import {DEVICES, getFontStyle, mq} from '@cc/theme';
import {Text} from '@cc/components';
import styled from '@emotion/styled';
import {unit} from '@/utils';

const MAX_WIDTH = 440;
const ICON_CONTAINER_SIZE = 40;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${({theme}) => theme.spacings.spacing250};
  background-color: ${({theme}) => theme.colors.backgroundSurface};
  ${mq[DEVICES.MOBILE]} {
    padding-left: ${({theme}) => theme.spacings.spacing150};
    padding-right: ${({theme}) => theme.spacings.spacing150};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  max-width: ${unit(MAX_WIDTH)};
`;

export const PreText = styled(Text)`
  margin-right: ${({theme}) => theme.spacings.spacing050};
  ${({theme}) => getFontStyle(theme, 'mediumBodyStrong200')};
`;

export const CustomText = styled(Text)`
  ${({theme}) => getFontStyle(theme, 'mediumBodyStrong200')};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${unit(ICON_CONTAINER_SIZE)};
  height: ${unit(ICON_CONTAINER_SIZE)};
  border-radius: ${unit(ICON_CONTAINER_SIZE / 2)};
  margin-bottom: ${({theme}) => theme.spacings.spacing100};
  background-color: ${({theme}) => theme.colors.backgroundEmphasizedInteractive};
`;
