import CCLogo from './cclogo.png';
import CVLogo from './cvlogo.png';
import CVLogoHorizontal from './cvlogohorizontal.svg';
import StudentListIcon from './student-list-icon.svg';
import DownloadIcon from './download-icon.svg';
import LockIcon from './lock-icon.png';

import InfoIcon from './info-icon.png';
import Ada from './ada.svg';
import DemoBoxAppIcon from './demo-box-app-icon.png';
import UserIcon from './user-icon.svg';
import SpeechOutlinedIcon from './icons/speech-outlined-icon.svg';

import SendOutlinedIcon from './icons/send-outlined-icon.svg';
import RefreshIcon from './icons/refresh.png';
import CloseIcon from './icons/close.svg';
import BgNeutralContent from './neutral-content.svg';

import BgBlue from './bg-blue.jpg';
import BgGreen from './bg-green.jpg';
import BgOrange from './bg-orange.jpg';
import BgSky from './bg-sky.jpg';
import BgTeal from './bg-teal.jpg';

import SmillyRedIcon from './icons/smily-red.svg';
import SmillyGreenIcon from './icons/smily-green.svg';
import SmillyYellowIcon from './icons/smily-yellow.svg';
import SmileyWhite from './icons/smiley-white.svg';

import CartIcon from './icons/cart.svg';
import SortIcon from './icons/sort.svg';
import DiamondIcon from './icons/emoji_u1f48e_dimond.png';
import WorkedSpokenIcon from './icons/worked-spoken.svg';
import WorkedSpeechIcon from './icons/worked-speech.svg';
import CheckGreenIcon from './icons/check-green.svg';
import CrossRedIcon from './icons/cross-red.svg';
import CommentOutlinedGrayIcon from './icons/ico.comment-gray-lined.svg';
import ListenOutlinedIcon from './icons/listen-outlined.svg';
import TrashBlue from './trash_blue.png';
import ReloadWhite from './iconReload_white.png';
import TrashWhite from './trash_white.png';
import ReloadBlue from './reload_blue.png';
import AddFilledIcon from './icons/add_filled.svg';
import AddCircleLined from './icons/ico.circle-add-lined.svg';
import AddOutlinedIcon from './icons/add_outlined.svg';
import WarningRedIcon from './icons/warning-red.svg';
import SmallTrash from './smalltrash_blue.png';
import SaveWhite from './save-lined.png';
import CheckWhite from './check-lined.png';
import ArrowWhite from './arrow-lined.png';
import CommentUpGrayFilled from './icons/ico.comment-gray-filled.svg';
import OpenedBookIcon from './icons/opened-book_icon.png';
import ArrowDownLined from './icons/arrow_down_lined.png';
import ChevronDownLined from './icons/chevron_down_lined.png';
import ChevronUpLined from './icons/chevron_up_lined.png';
import Feedback from './icons/feedback.png';

import WhitePencil from './icons/pencil-white.svg';
import BluePencil from './icons/pencil-blue.svg';

import TrialInfoIcon from './icons/info.svg';
import TrialShopIcon from './icons/shop.svg';

import BookBlue from './icons/book-blue.png';
import BookPile from './icons/book-pile.svg';
import ReviewQuiz from './icons/review-quiz.png';
import LogoutWhite from './icons/logout-white.svg';
import DefaultUser from './icons/default-user.png';
import GroupUsers from './icons/goup-users.png';
import EyeOutlinePrimary from './icons/eye-outline-primary.svg';
import MicrofoneIcon from './icons/micro.png';
import CalendarOutlinedIcon from './icons/calendar-outlined.svg';
import TrashOutlinedIcon from './icons/trash-outlined.svg';
import ArrowRightOutlinedIcon from './icons/arrow-right-outlined.svg';
import ArrowLeftOutlinedIcon from './icons/arrow-left-outlined.svg';
import MinusBlueIcon from './icons/minus-blue.png';
import PlusBlueLinedIcon from './icons/plus-blue-lined.svg';
import EyeFilledPrimary from './icons/eye-filled-primary.svg';
import LockClosedFilled from './icons/lock-closed-filled.svg';
import LockOpenLined from './icons/lock-open-lined.svg';
import AssignTaskLined from './icons/assign-task-lined.svg';

import Access22Book from './books/access-22.png';
import FocusOnSuccessPlusBook from './books/focus-on-success-plus.png';
import GoAheadBook from './books/go-ahead.png';
import HeadlightBook from './books/headlight.png';
import HighlightBook from './books/highlight.png';
import LighthouseGEBook from './books/lighthouse-ge.png';
import NeutralBook from './books/neutral.png';

import SkyColor from './classroom-colors/sky.jpg';
import TealColor from './classroom-colors/teal.jpg';
import GreenColor from './classroom-colors/green.jpg';
import OrangeColor from './classroom-colors/orange.png';
import GreyColor from './classroom-colors/grey.png';
import InactiveColor from './classroom-colors/inactive.png';

import Robot2 from './robot-2.svg';
import Robot3 from './robot-3.svg';
import IdeaBlue from './idea.svg';

import AppleStore from './apple-store.png';
import GooglePlay from './google-play.png';
import CloseBlue from './icons/close-blue.svg';
import CartWhite from './icons/cart-white.svg';
import InfoIconBlack from './icons/info-black.svg';
import InfoIconBlue from './icons/info-blue.svg';
import BubbleBot from './bubble-bot.svg';

import NotFound from '../icons/notfound.svg';
import MenuLinear from './icons/menu-linear.png';

import ProfileLined from './icons/ico.profile-lined.png';
import EditLined from './icons/ico.edit-lined.svg';
import HelpCircle from './icons/ico.help-circle.svg';
import ChevronLined from './icons/ico.chevron-lined.svg';
import EnvelopeLined from './icons/ico.envelope-lined.svg';
import LicenseLined from './icons/ico.license-lined.svg';
import LinkExternLined from './icons/ico.link-extern-lined.svg';

import CircleGreenCheckLined from './icons/ico.circle-check-green-lined.svg';
import CircleCrossEmptyLined from './icons/ico.circle-cross-empty-lined.svg';
import CircleRedCheckLined from './icons/ico.circle-cross-red-lined.svg';
import ClassLined from './icons/ico.class-lined.svg';
import ClassLinedGray from './icons/ico.class-lined-gray.svg';

const Images = {
  BgNeutralContent,
  BgBlue,
  BgGreen,
  BgOrange,
  BgSky,
  BgTeal,
  CCLogo,
  CVLogo,
  CVLogoHorizontal,
  StudentListIcon,
  DownloadIcon,
  InfoIcon,
  Ada,
  DemoBoxAppIcon,
  UserIcon,
  SpeechOutlinedIcon,
  SendOutlinedIcon,
  RefreshIcon,
  CloseIcon,
  SmillyRedIcon,
  SmillyGreenIcon,
  SmillyYellowIcon,
  SmileyWhite,
  CartIcon,
  LogoutWhite,
  SortIcon,
  DiamondIcon,
  WorkedSpokenIcon,
  WorkedSpeechIcon,
  OpenedBookIcon,
  ArrowDownLined,
  ChevronDownLined,
  ChevronUpLined,
  CheckGreenIcon,
  CrossRedIcon,
  CommentOutlinedGrayIcon,
  ListenOutlinedIcon,
  TrashBlue,
  ReloadWhite,
  TrashWhite,
  ReloadBlue,
  AddFilledIcon,
  AddCircleLined,
  AddOutlinedIcon,
  WarningRedIcon,
  SmallTrash,
  SaveWhite,
  CheckWhite,
  ArrowWhite,
  CommentUpGrayFilled,
  BookBlue,
  BookPile,
  ReviewQuiz,
  DefaultUser,
  TrialInfoIcon,
  TrialShopIcon,
  GroupUsers,
  EyeOutlinePrimary,
  MicrofoneIcon,
  CalendarOutlinedIcon,
  TrashOutlinedIcon,
  ArrowRightOutlinedIcon,
  ArrowLeftOutlinedIcon,
  MinusBlueIcon,
  PlusBlueLinedIcon,
  EyeFilledPrimary,
  LockClosedFilled,
  LockOpenLined,
  AssignTaskLined,
  Feedback,
  WhitePencil,
  BluePencil,
  Access22Book,
  FocusOnSuccessPlusBook,
  GoAheadBook,
  HeadlightBook,
  HighlightBook,
  LighthouseGEBook,
  NeutralBook,
  SkyColor,
  TealColor,
  GreenColor,
  OrangeColor,
  InactiveColor,
  IdeaBlue,
  Robot2,
  Robot3,
  AppleStore,
  GooglePlay,
  CloseBlue,
  CartWhite,
  InfoIconBlack,
  InfoIconBlue,
  BubbleBot,
  NotFound,
  MenuLinear,
  ProfileLined,
  EditLined,
  HelpCircle,
  ChevronLined,
  EnvelopeLined,
  LicenseLined,
  LinkExternLined,
  GreyColor,
  CircleGreenCheckLined,
  CircleCrossEmptyLined,
  CircleRedCheckLined,
  ClassLined,
  ClassLinedGray,
  LockIcon,
};

export default Images;
