import styled, { css } from 'styled-components'
import { Props } from './types' 

export const StyledScoreIconExample = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }

  span {
    font-size: 16px;
    line-height: 18px;

    ${props => props.color === 'green' && css`
      color: #00aa64;
    `}

    ${props => props.color === 'yellow' && css`
      color: #ffc107;
    `}

    ${props => props.color === 'red' && css`
      color: #b1201b;
    `}
  }
`
