import React from 'react';
import {useTranslation} from 'react-i18next';

import Images from '@/assets/images';

import {Props} from './types';
import {StyledScoreIconExample} from './styles';

const ScoreIconExample: React.FC<Props> = ({color}) => {
  const {t} = useTranslation('cornelsen');

  return (
    <StyledScoreIconExample color={color}>
      {color === 'green' && (
        <>
          <img src={Images.SmillyGreenIcon} alt="" />
          <span>7.5 - 10</span>
          <span>{t('Globals.HealthScore.Legend.Good')}</span>
        </>
      )}
      {color === 'yellow' && (
        <>
          <img src={Images.SmillyYellowIcon} alt="" />
          <span>3 - 7.4</span>
          <span>{t('Globals.HealthScore.Legend.Medium')}</span>
        </>
      )}
      {color === 'red' && (
        <>
          <img src={Images.SmillyRedIcon} alt="" />
          <span>0 - 2.9</span>
          <span>{t('Globals.HealthScore.Legend.Bad')}</span>
        </>
      )}
    </StyledScoreIconExample>
  );
};

export default ScoreIconExample;
