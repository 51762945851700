import styled from 'styled-components';

export const StyledContainer = styled.div<{markerColor: string}>`
  div {
    display: list-item;
    margin-left: 1.3em;
    list-style-type: disc;
  }
  div::marker {
    color: ${props => props.markerColor};
  }
`;

export const StyledDiamondIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const StyledStudentLink = styled.div`
  a {
    color: #1a73e8;
    text-decoration: none;
    font-weight: 600;

    @media (max-width: 321px) {
      white-space: break-spaces;
    }

    @media (max-width: 425px) {
      white-space: break-spaces;
    }

    :hover {
      text-decoration: underline;
    }

    span {
      margin-right: 5px;
    }
  }
`;
export const Wrapper = styled.div`
  @media (min-width: 768px) {
    div {
      overflow-y: visible;
    }
  }
`;
