export const Routes = {
  HEALTH: '/health',
  CLASSROOM: '/classroom',
  NEW_CLASSROOM: '/new-classroom',
  BOOK: '/book',
  NEW_BOOKS: '/new-books',
  STUDENT_SCORE: '/student-score',
  DOWNLOAD: '/download',
  IMPRINT: '/imprint',
  STUDENT_CODES: '/student-codes',
  LOGIN: '/login',
  LOGIN_BSP: '/login-bsp',
  LOGGED_OUT: '/logged_out',
  INSTRUCTIONS_BSP: '/instructions-bsp',
  INSTRUCTIONS: '/instructions',
  WILDCARD: '*',
} as const;
