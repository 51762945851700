import {UserType} from './types';

export const EMPTY_STRING = '';

export const MOBILE_APPLE_STORE =
  'https://apps.apple.com/us/app/chatclass-cornelsen/id1487598001?l=de&ls=1';

export const MOBILE_ANDROID_STORE =
  'https://play.google.com/store/apps/details?id=de.cornelsen.chatclass';

export const ORGANIZATIONS: {[key in UserType]: string} = {
  CORNELSEN: 'Cornelsen Verlag',
  BSP: 'Berliner Schulportal',
};
