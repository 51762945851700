import React from 'react';
import {Container} from './styles';
import {AppleStoreSvg, PlayStoreSvg} from '@cc/assets';
import {MOBILE_ANDROID_STORE, MOBILE_APPLE_STORE} from '@cc/shared';
import {isAndroid} from 'react-device-detect';

export const MobileAppStoreRedirection: React.FC = () => {
  const storeUrl = isAndroid ? MOBILE_ANDROID_STORE : MOBILE_APPLE_STORE;

  return <Container href={storeUrl}>{isAndroid ? <PlayStoreSvg /> : <AppleStoreSvg />}</Container>;
};
