import {getFontStyle} from '@cc/theme';
import {Text} from '@cc/components';
import styled from '@emotion/styled';
import {unit} from '@cc/utils';

const BLACK = '#000000';
const USER_MENU_HEIGHT = 60;

export const Container = styled.div<{isAuthenticated?: boolean}>`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.backgroundBackground};
  ${({isAuthenticated}) => isAuthenticated && `margin-top: ${unit(USER_MENU_HEIGHT)}`};
`;

export const Header = styled(Text)`
  width: 100%;
  color: ${BLACK};
  text-align: center;
  margin-top: ${({theme}) => theme.spacings.spacing250};
  ${({theme}) => getFontStyle(theme, 'mediumHeading500')};
  margin-bottom: ${({theme}) => theme.spacings.spacing250};
`;

export const InstructionsContainer = styled.div`
  padding-top: ${({theme}) => theme.spacings.spacing250};
  background-color: ${({theme}) => theme.colors.backgroundSurface};
`;
