import {Text} from '../../atoms';
import {unit} from '@cc/utils';
import styled from '@emotion/styled';

export const QR_CODE_SIZE = 120;
export const Container = styled.div`
  display: flex;
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${unit(QR_CODE_SIZE)};
`;

export const CustomText = styled(Text)`
  text-align: center;
  ${({theme}) => theme.typography.mediumBodyStrong200};
`;

export const Separator = styled.div`
  width: ${({theme}) => theme.spacings.spacing250};
`;
