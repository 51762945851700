import styled from 'styled-components'

export const InfoBoxContainer = styled.div`
  position: relative;
  margin-top: -8px;
  margin-left: 4px;
`

export const InfoBoxContent = styled.div<{ isOpen: boolean }>`
  z-index: 20;
  position: fixed;
  top: 50%;
  left: 20px;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  width: calc(100vw - 40px);
  height: 400px;
  margin-top: -200px;
  padding: 20px;
  background: #fff;  
  box-sizing: border-box;

  p {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: normal;
    white-space: normal;
  }

  ${props => props.theme.mediaQueries.medium} {
    position: absolute;
    top: 18px;
    left: 8px;
    width: 360px;
    height: auto;
    margin-top: 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .1);
  }
`

export const InfoBoxIcon = styled.div`
  cursor: pointer;
`

export const InfoBoxClose = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;

  ${props => props.theme.mediaQueries.medium} {
    display: none;
  }
`

export const InfoBoxBackdrop = styled.div<{ isOpen: boolean }>`
  content: "";
  z-index: 19;
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .2);

  ${props => props.theme.mediaQueries.medium} {
    display: none;
  }
`