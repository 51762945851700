import React, {ReactElement} from 'react';
import {Container, CustomText, IconContainer, PreText, TextContainer} from './styles';
import {useTranslation} from 'react-i18next';

interface Props {
  icon?: ReactElement;
  preText?: string;
  textKey: string;
  bottomContent?: ReactElement;
}

export const Instruction: React.FC<Props> = ({icon, textKey, preText, bottomContent}) => {
  const {t} = useTranslation('instructionsBsp');

  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <TextContainer>
        <PreText content={preText} />
        <CustomText content={t(textKey)} />
      </TextContainer>
      {bottomContent}
    </Container>
  );
};
