import {format} from 'date-fns';
import * as DateFns from 'date-fns';
import Images from '../assets/images';
import {CornelsenTheme} from '@cc/theme';
import {Store} from 'react-notifications-component';

export const FilterTimeValues = {
  ALL: 0,
  TODAY: 1,
  LAST_SEVEN: 2,
  LAST_THIRTY: 3,
  LAST_FOURTEEN: 4,
};

export type FilterTime = {
  startDate: string;
  endDate: string;
};

const Helper = {
  showNotification: (title: string, message: string, type: any) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  },

  getValuePercent: (value: number) => {
    if (!value || value < 0) {
      return 0;
    }

    return (value * 100).toFixed(0);
  },
  getFormatDate: (date: string, formatLocale = 'yyyy-MM-dd', addDays = 0) => {
    const newDate = DateFns.addDays(DateFns.parseISO(date), addDays);
    return DateFns.format(newDate, formatLocale);
  },
  getMetaHealthScore: (health_score: number) => {
    switch (true) {
      case health_score > 7.4: {
        return {
          text: 'High',
          color: 'green',
          icon: Images.SmillyGreenIcon,
        };
      }
      case health_score > 2.9 && health_score <= 7.4: {
        return {
          text: 'Mid',
          color: CornelsenTheme.colors.yellow,
          icon: Images.SmillyYellowIcon,
        };
      }
      case health_score >= 0 && health_score <= 2.9: {
        return {
          text: 'Low',
          color: CornelsenTheme.colors.red,
          icon: Images.SmillyRedIcon,
        };
      }
      default: {
        return {
          text: ' - ',
          color: CornelsenTheme.colors.black,
          icon: Images.SmillyRedIcon,
        };
      }
    }
  },
  getIntervalDatesByFilterIndex(index: number, formatLocale = 'yyyy-MM-dd') {
    switch (index) {
      case FilterTimeValues.TODAY: {
        return {
          startDate: format(new Date(), formatLocale),
          endDate: format(DateFns.addDays(new Date(), 1), formatLocale),
        };
      }
      case FilterTimeValues.LAST_SEVEN: {
        return {
          startDate: format(DateFns.subDays(new Date(), 6), formatLocale),
          endDate: format(DateFns.addDays(new Date(), 1), formatLocale),
        };
      }
      case FilterTimeValues.LAST_FOURTEEN: {
        return {
          startDate: format(DateFns.subDays(new Date(), 14), formatLocale),
          endDate: format(DateFns.addDays(new Date(), 1), formatLocale),
        };
      }
      case FilterTimeValues.LAST_THIRTY: {
        return {
          startDate: format(DateFns.subDays(new Date(), 29), formatLocale),
          endDate: format(DateFns.addDays(new Date(), 1), formatLocale),
        };
      }
      default: {
        return {
          startDate: '',
          endDate: format(DateFns.addDays(new Date(), 1), formatLocale),
        };
      }
    }
  },
  getNextIntervalDates: (
    index: number,
    currentTime: {
      startDate: string;
      endDate: string;
    },
    formatLocale = 'yyyy-MM-dd',
  ) => {
    const {endDate} = currentTime;
    const {format, addDays, parseISO} = DateFns;

    const addDaysWithFormat = (date: string, numberOfDays: number) => {
      return format(addDays(parseISO(date), numberOfDays), formatLocale);
    };

    switch (index) {
      case FilterTimeValues.ALL: {
        return {
          startDate: '',
          endDate: '',
        };
      }
      case FilterTimeValues.TODAY: {
        const newStartDate = addDaysWithFormat(endDate, 0);

        const newEndDate = addDaysWithFormat(endDate, 1);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      case FilterTimeValues.LAST_SEVEN: {
        const newStartDate = addDaysWithFormat(endDate, 0);

        const newEndDate = addDaysWithFormat(endDate, 7);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      case FilterTimeValues.LAST_FOURTEEN: {
        const newStartDate = addDaysWithFormat(endDate, 0);

        const newEndDate = addDaysWithFormat(endDate, 14);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      case FilterTimeValues.LAST_THIRTY: {
        const newStartDate = addDaysWithFormat(endDate, 0);

        const newEndDate = addDaysWithFormat(endDate, 30);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      default: {
        return {
          startDate: '',
          endDate: '',
        };
      }
    }
  },
  getPrevIntervalDates: (
    index: number,
    currentTime: {
      startDate: string;
      endDate: string;
    },
    formatLocale = 'yyyy-MM-dd',
  ) => {
    const {startDate} = currentTime;
    const {format, subDays, parseISO} = DateFns;

    const subDaysWithFormat = (date: string, numberOfDays: number) => {
      return format(subDays(parseISO(date), numberOfDays), formatLocale);
    };

    switch (index) {
      case FilterTimeValues.ALL: {
        return {
          startDate: '',
          endDate: '',
        };
      }
      case FilterTimeValues.TODAY: {
        const newStartDate = subDaysWithFormat(startDate, 1);

        const newEndDate = subDaysWithFormat(startDate, 0);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      case FilterTimeValues.LAST_SEVEN: {
        const newStartDate = subDaysWithFormat(startDate, 7);

        const newEndDate = subDaysWithFormat(startDate, 0);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      case FilterTimeValues.LAST_FOURTEEN: {
        const newStartDate = subDaysWithFormat(startDate, 14);

        const newEndDate = subDaysWithFormat(startDate, 0);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      case FilterTimeValues.LAST_THIRTY: {
        const newStartDate = subDaysWithFormat(startDate, 30);

        const newEndDate = subDaysWithFormat(startDate, 0);

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      }
      default: {
        return {
          startDate: '',
          endDate: '',
        };
      }
    }
  },
  getMetaColors: (color: string) => {
    switch (color) {
      case 'green': {
        return {
          background: Images.BgGreen,
          highlightColor: '#aad750',
        };
      }
      case 'orange': {
        return {
          background: Images.BgOrange,
          highlightColor: '#f6851c',
        };
      }
      case 'blue': {
        return {
          background: Images.BgSky,
          highlightColor: '#7dc8dc',
        };
      }
      case 'teal': {
        return {
          background: Images.BgTeal,
          highlightColor: '#46bea0',
        };
      }
      case 'sky': {
        return {
          background: Images.BgSky,
          highlightColor: '#7dc8dc',
        };
      }
      default: {
        return {
          background: Images.BgBlue,
          highlightColor: '#7dc8dc',
        };
      }
    }
  },
  getDatetimeWithOffset: (date: string, time: string) => {
    const offset = new Date().getTimezoneOffset() / 60;

    const offsetSign = offset <= 0 ? '+' : '-';

    return `${date}T${time}:00.000${offsetSign + ('0' + Math.abs(offset)).slice(-2)}:00`;
  },
};

export default Helper;
