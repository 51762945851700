import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {BiCaretDown, BiCaretUp} from 'react-icons/bi';
import {Button, Card, Container, Table, Text} from 'cc-web-components';
import {useSelector} from 'react-redux';

import Images from '@/assets/images';
import ScoreIconExample from '@/components/ScoreIconExample';
import {ClassroomStudentRow} from '@/services/ApiCv/types';
import useDeviceDetectionSm from '@/hooks/IsSmall';
import useDeviceDetectionMobile from '@/hooks/IsMobile';

import InfoBox from '../InfoBox';
import {StyledStudentLink, StyledDiamondIcon, StyledContainer, Wrapper} from './styles';
import {Routes} from '@cc/shared';
import {selectIsFeatureAvailable} from '@/redux/Rights';
import {PlusBlueIcon} from '@/components/Icons';

const {CLASSROOM} = Routes;

type Props = {
  color: string;
  students: ClassroomStudentRow[];
  classroomId?: string;
  hideButtonAddStudent?: boolean;
  showStudentsForm: () => void;
  addStudentsRequest: (addedStudents: any[]) => Promise<void>;
};

type SortConfigDirection = 'ASC' | 'DESC' | null;

type SortConfig = {
  key: null | keyof ClassroomStudentRow;
  direction: SortConfigDirection;
};

const StudentScoreList: React.FC<Props> = ({
  color,
  students,
  classroomId,
  showStudentsForm,
  addStudentsRequest,
  ...props
}: Props) => {
  const {t} = useTranslation('cornelsen');
  const {colors} = useTheme();
  const {isSmall} = useDeviceDetectionSm();
  const {isMobile} = useDeviceDetectionMobile();
  const isAddStudentVisible = useSelector(selectIsFeatureAvailable('STUDENT', 'C'));
  const isStudentCodeVisible = useSelector(selectIsFeatureAvailable('STUDENT_CODE', 'R'));

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'student_name',
    direction: 'ASC',
  });

  const sortedStudents = React.useMemo(() => {
    const sortedStudents = [...students].sort((a, b) => {
      if (sortConfig.key) {
        if (!a.student_id || !a[sortConfig.key]) return 1;

        if (!b.student_id || !b[sortConfig.key]) return -1;

        const parsedA = a[sortConfig.key].toString().toLocaleLowerCase();
        const parsedB = b[sortConfig.key].toString().toLocaleLowerCase();

        return parsedA.localeCompare(parsedB, undefined, {numeric: true, sensitivity: 'base'}) === 1
          ? sortConfig.direction === 'ASC'
            ? 1
            : -1
          : sortConfig.direction === 'ASC'
          ? -1
          : 1;
      }

      return 0;
    });

    return sortedStudents;
  }, [students, sortConfig]);

  const HealthScoreTooltipContent = (
    <>
      <p dangerouslySetInnerHTML={{__html: t('Classroom.StudentsScore.InfoHealthScore.Part1')}}></p>
      <p dangerouslySetInnerHTML={{__html: t('Classroom.StudentsScore.InfoHealthScore.Part2')}}></p>
      <Container justifyContent="space-around">
        <ScoreIconExample color="green" />

        <ScoreIconExample color="yellow" />

        <ScoreIconExample color="red" />
      </Container>
    </>
  );

  const requestSort = (key: keyof ClassroomStudentRow) => {
    let direction: SortConfigDirection = 'ASC';

    if (sortConfig.direction === 'ASC') {
      direction = 'DESC';
    }

    setSortConfig({key, direction});
  };

  const getIconsOrdered = (columnName: string) => {
    let opacityIconUp = 0.5;
    let opacityIconDown = 0.5;

    if (!!sortConfig.key && sortConfig.key === columnName) {
      opacityIconUp = sortConfig.direction === 'ASC' ? 1 : 0.5;
      opacityIconDown = sortConfig.direction === 'DESC' ? 1 : 0.5;
    }

    return (
      <Container flexDirection="column" marginRight={1}>
        <Container marginBottom={-2}>
          <BiCaretUp color={colors.primary} style={{opacity: opacityIconUp}} />
        </Container>

        <BiCaretDown color={colors.primary} style={{opacity: opacityIconDown}} />
      </Container>
    );
  };

  const renderColumnSortable = (columnName: keyof ClassroomStudentRow, children: any) => {
    return (
      <Container style={{cursor: 'pointer'}} onClick={() => requestSort(columnName)}>
        <Container
          flexDirection="column"
          marginRight={isMobile ? 0 : 1}
          display={isMobile ? 'none' : 'inline-block'}>
          {getIconsOrdered(columnName)}
        </Container>

        {children}
      </Container>
    );
  };

  return (
    <Container flexDirection="column" mb={4}>
      <Container alignItems="center" justifyContent="space-between" mb={2}>
        <Text value={t('Classroom.StudentsScore.Title')} size={20} fontWeight={600} />
        {isAddStudentVisible && !props.hideButtonAddStudent && (
          <Button
            variant="link"
            fontFamily={'CV_Source_Sans'}
            value={t('Classroom.StudentsScore.ButtonFooterText')}
            padding={'10px 0px 10px 12px'}
            leftContent={
              <Container marginRight={'4px'}>
                <PlusBlueIcon height={24} width={24} />
              </Container>
            }
            onClick={showStudentsForm}
            fontSize={16}
            fontWeight={400}
          />
        )}
      </Container>
      <Wrapper>
        <Card flexDirection="column" contentPadding={0}>
          <Table
            style={{width: '100%', boxShadow: 'unset', fontSize: isSmall ? '14px' : '20px'}}
            data={sortedStudents}
            columns={[
              {
                header: renderColumnSortable(
                  'student_name',
                  <Container justifyContent="center" alignItems="center" style={{lineHeight: 1}}>
                    {t('Classroom.StudentsScore.TableHeader.StudentName')} ({students.length})
                  </Container>,
                ),
                highlightColor: color,
                renderItem: (item: ClassroomStudentRow) => {
                  const onboardedLink = `${CLASSROOM}/${item.student_classroom_id}/student/${item.student_id}`;
                  const notOnboardedLink = `${CLASSROOM}/${item.student_classroom_id}/student?code=${item.student_code}`;

                  return (
                    <StyledStudentLink>
                      <Link to={item.student_id ? onboardedLink : notOnboardedLink}>
                        {item.student_emoji && <span>{item.student_emoji}</span>}{' '}
                        {item.student_name}
                      </Link>
                      {isStudentCodeVisible && (
                        <Text size={16} color="#1a73e8" value={`#${item.student_code}`} />
                      )}
                    </StyledStudentLink>
                  );
                },
              },
              {
                header: renderColumnSortable(
                  'competencies_health_score_text',
                  <Container flexDirection="row" justifyContent="center" alignItems="center">
                    <Container style={{lineHeight: 1}}>
                      {isMobile
                        ? t('Classroom.StudentsScore.TableHeader.HealthScoreMobile')
                        : t('Classroom.StudentsScore.TableHeader.HealthScore')}
                    </Container>
                    <InfoBox content={HealthScoreTooltipContent} />
                  </Container>,
                ),
                renderItem: (item: ClassroomStudentRow) =>
                  item.has_compentence ? (
                    <StyledContainer markerColor={item.competencies_health_score_color}>
                      <Text
                        size={16}
                        color={item.competencies_health_score_color}
                        value={t(
                          `Globals.HealthScore.LegendStatus.${item.competencies_health_score_text}`,
                        )}
                      />
                    </StyledContainer>
                  ) : (
                    <Container>-</Container>
                  ),
              },
              isMobile
                ? {
                    header: null,
                    highlightColor: null,
                    renderItem: () => {},
                  }
                : {
                    header: renderColumnSortable(
                      'competencies_points',
                      <Container
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{lineHeight: 1}}>
                        {t('Classroom.StudentsScore.TableHeader.Points')}
                      </Container>,
                    ),
                    renderItem: (item: ClassroomStudentRow) => (
                      <Container alignItems="center">
                        <StyledDiamondIcon src={Images.DiamondIcon} alt="" />

                        <Text
                          value={!!item.competencies_points ? <>{item.competencies_points}</> : 0}
                          size={16}
                          fontWeight={600}
                          color={colors.primary}
                        />
                      </Container>
                    ),
                  },
              {
                header: renderColumnSortable(
                  'competencies_vocabulary_tasks_average',
                  <Container justifyContent="center" alignItems="center" style={{lineHeight: 1}}>
                    {t('Classroom.StudentsScore.TableHeader.Correctly')}
                  </Container>,
                ),
                renderItem: (item: ClassroomStudentRow) => (
                  <>{item.competencies_vocabulary_tasks_average}</>
                ),
              },
            ]}
          />
        </Card>
      </Wrapper>
    </Container>
  );
};

export default StudentScoreList;
