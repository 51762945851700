import React from 'react';
import {Container, Header, InstructionsContainer} from './styles';
import {Instruction, SmartBanner, StoreInfo} from './components';
import {DownloadSvg, LoginSvg, CheckSvg} from '@cc/assets';
import {useTranslation} from 'react-i18next';

const instructions = [
  {
    preText: '1.',
    tKey: 'download',
    icon: <DownloadSvg />,
    bottomContent: <StoreInfo />,
  },
  {
    preText: '2.',
    tKey: 'login',
    icon: <LoginSvg />,
  },
  {
    preText: '3.',
    tKey: 'use',
    icon: <CheckSvg />,
  },
];

interface Props {
  isAuthenticated?: boolean;
}

export const InstructionsBspPage: React.FC<Props> = ({isAuthenticated}) => {
  const {t} = useTranslation('instructionsBsp');

  return (
    <Container isAuthenticated={isAuthenticated}>
      <SmartBanner />
      <Header content={t('title')} />
      <InstructionsContainer>
        {instructions.map(({icon, preText, tKey, bottomContent}) => (
          <Instruction
            icon={icon}
            key={preText}
            textKey={tKey}
            preText={preText}
            bottomContent={bottomContent}
          />
        ))}
      </InstructionsContainer>
    </Container>
  );
};
