/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback, useRef} from 'react';
import {useTheme} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {Container, Page} from 'cc-web-components';
import {ReactNotifications} from 'react-notifications-component';

// import OfferSticker from '@/components/OfferSticker'
import ApiHomePageService from '@/services/ApiCv/ApiHomePageService';
import {getLicense, stopLoading} from '@/redux/Session';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import VocabularyProblems from '../NewClassroom/components/VocabularyProblems';
import StudentAllActivities from '../NewClassroom/components/SpeakingAssignments';
import {ClassroomCards, DeleteModal, Features, Heading, StudentsProgress} from './components';
import {PriceTeaser} from './components';
import {LICENSE_BAR_MIN_DAYS_TO_SHOW} from '@/components/LicenseBar';
import {AnnouncementModal} from '@/components/ModalAnnouncement';
import Footer from '@/components/Footer';
import {Classrooms, LocationState} from './types';
import './styles.css';

import Helpers from '@/helper';

const BACKGROUND =
  'https://res.cloudinary.com/doje19w7m/image/upload/q_auto:good/v1623679203/repo/headers/Header_App-Blue.jpg';
const DEFAULT_FILTERS = {startDate: '2021-01-01', endDate: '2021-07-02'};

export const HomePage: React.FC = () => {
  const {colors} = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation('cornelsen');
  const license = useSelector(getLicense);
  const [, setShowOfferSticker] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  const [activeAction, setActiveAction] = useState(0);
  const classroomId = useRef('');
  const classroomName = useRef('');
  const newsAreAutomaticallyOpen = localStorage.getItem('areNewsAutomaticallyOpen') === 'true';

  const [classrooms, setClassrooms] = useState<Classrooms>();
  const [{onOpenedAssignLicense}, {toggleAssignLicense, updateClassroomIdToReactiveLicense}] =
    useAssignActivityContext();

  const getClassrooms = useCallback(() => {
    ApiHomePageService['getAllClassrooms']()
      .then(data => {
        setClassrooms(
          data.data.classrooms.map((item: any) => {
            if (!!item.license) {
              item.licence = item.license;
            } else {
              item.licence = {expired: true};
              item.license = item.licence;
            }

            return item;
          }),
        );
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  }, []);

  const handleLocationState = useCallback(() => {
    if (!location.state) {
      return;
    }

    const state = location.state as LocationState;

    if (!state.alert) {
      return;
    }
    Helpers.showNotification(
      t('Globals.Notification.Success.Title'),
      state.alert.message,
      'success',
    );
    navigate('/', {state: {}, replace: true});
    getClassrooms();
  }, [location.state]);

  const handleLicenseExpiration = useCallback(async () => {
    if (!license) {
      return;
    }

    setShowOfferSticker(license.days_to_expire <= LICENSE_BAR_MIN_DAYS_TO_SHOW || license.expired);
  }, []);

  const handleOnDeleteModalClose = useCallback(() => {
    setOpenDeleteModal(false);
    setActiveAction(0);
  }, []);

  const handleClassroomDelete = useCallback(() => {
    setActiveAction(0);
    setOpenDeleteModal(false);
  }, []);

  const handleAnnounceModalClose = useCallback(() => {
    setOpenAnnouncement(false);
    document.body.style.overflow = 'unset';
  }, []);

  const showAnnouncementModal = useCallback(() => {
    setOpenAnnouncement(true);
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    if (!newsAreAutomaticallyOpen) {
      showAnnouncementModal();
      localStorage.setItem('areNewsAutomaticallyOpen', 'true');
    }
  }, []);

  const handleOnClassroomSelect = useCallback(
    (index: number, classroomID: string, classroomNAME: string) => {
      setActiveAction(index);
      if (index === 1) {
        classroomId.current = classroomID;
        updateClassroomIdToReactiveLicense(classroomID);
      }
      if (index === 2) {
        classroomId.current = classroomID;
        classroomName.current = classroomNAME;
      }
    },
    [],
  );

  useEffect(() => {
    if (onOpenedAssignLicense) {
      return;
    }

    setActiveAction(0);
  }, [onOpenedAssignLicense]);

  useEffect(() => {
    handleLocationState();
  }, []);

  useEffect(() => {
    handleLicenseExpiration();
  }, []);

  useEffect(() => {
    if (activeAction === 1) {
      toggleAssignLicense();
      return;
    }

    if (activeAction === 2) {
      setOpenDeleteModal(true);
    }
  }, [activeAction]);

  return (
    <>
      <ReactNotifications isMobile={true} breakpoint={425} />
      <Page
        headerContent={<></>}
        hideBar
        titleSize={35}
        footerContent={<Footer />}
        title={t('Home.Title')}
        backgroundImage={BACKGROUND}>
        <PriceTeaser />
        <Heading onLinkClick={showAnnouncementModal} isNewsLinkHidden={false} />
        <ClassroomCards classrooms={classrooms} onClassroomSelect={handleOnClassroomSelect} />
        <StudentsProgress getClassrooms={getClassrooms} />

        <Container alignItems="center" justifyContent="space-between">
          <StudentAllActivities
            showMore={'home'}
            color={colors.green}
            activeFilter={5}
            filtersOfTime={DEFAULT_FILTERS}
            classrooms={classrooms}
          />
        </Container>

        <Container alignItems="center" justifyContent="space-between">
          <VocabularyProblems
            showMore={'home'}
            color={colors.green}
            activeFilter={5}
            filtersOfTime={DEFAULT_FILTERS}
            classrooms={classrooms}
          />
        </Container>

        <Features />
        <DeleteModal
          show={openDeleteModal}
          classroomId={classroomId.current}
          classroomName={classroomName.current}
          closeRequest={handleOnDeleteModalClose}
          onClassroomDelete={handleClassroomDelete}
        />
        <AnnouncementModal show={openAnnouncement} closeRequest={handleAnnounceModalClose} />
        {/* {!isMobile && showOfferSticker && IsShowFeaturesUser && <OfferSticker />} */}
      </Page>
      {/* <BottomBanner /> */}
    </>
  );
};
