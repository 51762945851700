import React from 'react';
import {Container, CustomText, QRContainer, QR_CODE_SIZE, Separator} from './styles';
import {AppleStoreQRSvg, PlayStoreQRSvg} from '@cc/assets';

type DisplayOnly = 'apple' | 'google';

interface Props {
  displayOnly?: DisplayOnly;
}

export const MobileStoreQRCode: React.FC<Props> = ({displayOnly}) => {
  return (
    <Container>
      {(!displayOnly || displayOnly === 'apple') && (
        <QRContainer>
          <AppleStoreQRSvg height={QR_CODE_SIZE} width={QR_CODE_SIZE} />
          <CustomText content="Apple App Store" />
        </QRContainer>
      )}

      {!displayOnly && <Separator />}

      {(!displayOnly || displayOnly === 'google') && (
        <QRContainer>
          <PlayStoreQRSvg height={QR_CODE_SIZE} width={QR_CODE_SIZE} />
          <CustomText content="Google Play Store" />
        </QRContainer>
      )}
    </Container>
  );
};
