import React, {useState} from 'react';
import {useTheme} from 'styled-components';
import {IoMdClose} from 'react-icons/io';
import {Container} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {IoInformationCircleOutline} from 'react-icons/io5';

import ScoreIconExample from '../../ScoreIconExample';
import useDeviceDetection from '@/hooks/IsMobile';

import {
  InfoBoxContainer,
  InfoBoxContent,
  InfoBoxIcon,
  InfoBoxClose,
  InfoBoxBackdrop,
} from './styles';

const HealthScore: React.FC = () => {
  const {t} = useTranslation('cornelsen');
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();

  const [isOpen, setOpen] = useState(false);

  return (
    <InfoBoxContainer>
      <InfoBoxIcon>
        <IoInformationCircleOutline
          onClick={event => {
            event.stopPropagation();
            setOpen(true);
          }}
          onMouseEnter={() => !isMobile && setOpen(true)}
          onMouseLeave={() => !isMobile && setOpen(false)}
          color={colors.primary}
          size={20}
          width={15}
          tabIndex={0}
        />
      </InfoBoxIcon>
      <InfoBoxContent isOpen={isOpen}>
        <InfoBoxClose
          onClick={event => {
            event.stopPropagation();
            setOpen(false);
          }}
        >
          <IoMdClose size={24} color={colors.gray[2]} />
        </InfoBoxClose>
        {
          <>
            <p dangerouslySetInnerHTML={{__html: t('Globals.HealthScore.InfoText.Part1')}}></p>

            <p dangerouslySetInnerHTML={{__html: t('Globals.HealthScore.InfoText.Part2')}}></p>

            <Container justifyContent="space-around">
              <ScoreIconExample color="green" />

              <ScoreIconExample color="yellow" />

              <ScoreIconExample color="red" />
            </Container>
          </>
        }
      </InfoBoxContent>
      <InfoBoxBackdrop
        isOpen={isOpen}
        onClick={event => {
          event.stopPropagation();
          setOpen(false);
        }}
      />
    </InfoBoxContainer>
  );
};

export default HealthScore;
