import React from 'react';
import {Container} from './styles';
import {Text} from '@/components';

export const InstructionsPage: React.FC = () => {
  return (
    <Container>
      <Text content="This is instructions page" />
    </Container>
  );
};
