import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { IoMdClose } from 'react-icons/io'
import { IoInformationCircleOutline } from 'react-icons/io5'

import useDeviceDetection from '@/hooks/IsMobile'
import { Props } from './types'
import {
  InfoBoxContainer,
  InfoBoxContent,
  InfoBoxIcon,
  InfoBoxClose,
  InfoBoxBackdrop,
} from './styles'

const InfoTooltip: React.FC<Props> = ({ content }) => {
  const [isOpen, setOpen] = useState(false)
  const { colors } = useTheme()
  const { isMobile } = useDeviceDetection()

  return (
    <InfoBoxContainer>
      <InfoBoxIcon>
        <IoInformationCircleOutline
          onClick={(event) => {
            event.stopPropagation()
            setOpen(true)
          }}
          onMouseEnter={() => !isMobile && setOpen(true)}
          onMouseLeave={() => !isMobile && setOpen(false)}
          color={colors.primary}
          size={20}
          width={15}
          tabIndex={0}
        />
      </InfoBoxIcon>
      <InfoBoxContent isOpen={isOpen}>
        <InfoBoxClose
          onClick={(event) => {
            event.stopPropagation()
            setOpen(false)
          }}
        >
          <IoMdClose size={24} color={colors.gray[2]} />
        </InfoBoxClose>
        {content}
      </InfoBoxContent>
      <InfoBoxBackdrop
        isOpen={isOpen}
        onClick={(event) => {
          event.stopPropagation()
          setOpen(false)
        }}
      />
    </InfoBoxContainer>
  )
}

export default InfoTooltip
