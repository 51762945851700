import {MobileAppStoreRedirection, MobileStoreQRCode} from '@cc/components';
import React from 'react';
import {Container} from './styles';
import {isTablet, isMobile} from 'react-device-detect';

export const StoreInfo: React.FC = () => {
  const redirectionComponent =
    isMobile || isTablet ? <MobileAppStoreRedirection /> : <MobileStoreQRCode />;

  return <Container isQRCode={!isMobile}>{redirectionComponent}</Container>;
};
