import {EMPTY_STRING} from '@cc/shared';
import Images from '@/assets/images';

type BackgroundColors = 'green' | 'blue' | 'orange' | 'teal';
const BACKGROUNDS: {[key in BackgroundColors]: string} = {
  green:
    'https://res.cloudinary.com/doje19w7m/image/upload/q_auto:good/v1623676844/repo/headers/Header_Green.jpg',
  blue: 'https://res.cloudinary.com/doje19w7m/image/upload/q_auto:good/v1623676843/repo/headers/Header_Sky.jpg',
  orange:
    'https://res.cloudinary.com/doje19w7m/image/upload/q_auto:good/v1623676844/repo/headers/Header_Orange.jpg',
  teal: 'https://res.cloudinary.com/doje19w7m/image/upload/q_auto:good/v1623676846/repo/headers/Header_Teal.jpg',
};

const getUrl = (image: string) => {
  return 'url(' + image + ')';
};

export const getBackgroundImage = (color: string, isInactive?: boolean | null) => {
  if (isInactive) {
    return getUrl(Images.InactiveColor);
  }

  const imageUrl = BACKGROUNDS[color as BackgroundColors];

  if (!imageUrl) {
    return EMPTY_STRING;
  }

  return getUrl(imageUrl);
};
